import { useEffect, useState, React } from "react";
import MainButton from "./mainButton";
import { fetchPaymentMethod } from "../api/fetchApi";
import {
  FundCardApi,
  createCryptoOrder,
  createOrder,
  fundCryptoCardApi,
} from "../api/createOrder";
import { createFirstPayment, cryptoPaymentLinkGenerte } from "../api/payment";
import VirtualCardShimmer from "./VirtualCardShimmer";
import { UsdtLogo, VisaLogo, masterCardLogo } from "../assets";
import CheckIcon from "./Check";
import UsdIcon from "./Icons/UsdIcon";
import Copy from "./Icons/Copy";
import CopiedIcon from "./Icons/Copied";
import { calculatePrice, calculateUsdtPrice } from "../constants/calculations";
import { useBackButton } from "../Contexts/BackButtonContext";
import QRCode from "react-qr-code";
import CircularInfoIcon from "./Icons/CircularInfoIcon";

const PaymentModal = ({ card }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [price, setPrice] = useState(0);
  const [holder, setHolder] = useState("");
  // const [discount, setDiscount] = useState(0);
  // const [addresTitle, setAddressTitle] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [activeMethod, setactiveMethod] = useState("");
  const [activeMethodError, setActiveMethodError] = useState(false);
  const [ftError, setFtError] = useState("");
  const [txn, setTxn] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [cryptoReference, setCryptoReferecne] = useState("");
  const walletAddress = "TNeKPrukbU15UT25s2oNFRU62uvbfHbgrk";

  const { step, setStep, label, selectedPayment, setSelecedPayment } =
    useBackButton();

  const handleCopy = (value) => {
    setIsCopied(value);
    const textarea = document.createElement("textarea");
    textarea.value = value;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };
  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  const paymentMethodChange = async (value, type) => {
    setSelecedPayment(type);
    setactiveMethod(value);
    if (paymentMethods.length > 0) {
      let foundPaymentMethod = paymentMethods.find(
        (method) => method.title === value
      );
      if (foundPaymentMethod) {
        console.log(price);
        setSelectedPaymentMethod(foundPaymentMethod);
        if (foundPaymentMethod.type === "chapa") {
          try {
            // var raw = JSON.stringify({
            //   amount: Number(price.toString().replace(/,/g, "")).toFixed(2),
            // });
            // console.log(raw);
            // let res = await createFirstPayment(raw);
            // let resJson = JSON.parse(res.response);
            // setChapaAddress(resJson.data.checkout_url);
            // setAddressTitle(foundPaymentMethod.title);
          } catch (error) {
            console.error("Error:", error);
          }
        } else {
          setAddress(foundPaymentMethod.address);
          setHolder(foundPaymentMethod.descripton);
          console.log(foundPaymentMethod.descripton);
          // setAddressTitle(foundPaymentMethod.title);
        }
      }
    }
  };
  const cryptoOrderSubmit = () => {
    if (amount < 15 || amount > 1000) {
      return setAmountError("The amount range is $15 - $1000");
    }
    if (!amount) return setAmountError("The amount field is requird!");
    getPrice(amount);
    // console.log(selectedPayment);
    if (selectedPayment === "crypto") {
      // console.log(step);
      if (step === 1) {
        setFtError("");
        // if (!txn) return setFtError("The txn / reference field is required!");
        setIsLoading(true);
        // console.log(selectedPayment);
        var raw = "";
        if (label === "createcard") {
          raw = JSON.stringify({
            name: "YehaCard",
            paymont_method: 100,
            txn: cryptoReference.result.txn_id,
            address: cryptoReference.result.address,
            amount: amount,
            card_type: "1",
            paid_amount: cryptoReference.result.amount,
            checkout_url: cryptoReference.result.checkout_url,
            status_url: cryptoReference.result.status_url,
            qrcode_url: cryptoReference.result.qrcode_url,
          });

          createCryptoOrder(raw)
            .then((res) => res.json())
            .then((res) => {
              // console.log(res.data.status);
              if (!res.data.status) {
                return;
              } else {
                window.location.reload();
              }
            })
            .catch((err) => {
              return;
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          // console.log("started");
          raw = JSON.stringify({
            amount: amount,
            payment_method: "usdt",
            txn: cryptoReference.result.txn_id,
            paid_amount: cryptoReference.result.amount,
            checkout_url: cryptoReference.result.checkout_url,
            status_url: cryptoReference.result.status_url,
            qrcode_url: cryptoReference.result.qrcode_url,
            address: cryptoReference.result.address,
          });
          fundCryptoCardApi(card, raw)
            .then((res) => res.json())
            .then((res) => {
              if (!res.status) {
                // console.log(res);
                return;
              } else {
                window.location.reload();

                // console.log(res.status);
              }
            })
            .catch((err) => {
              return;
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    } else {
      // console.log(selectedPayment);
    }
  };
  const submit = () => {
    console.log(selectedPayment);
    if (amount < 15 || amount > 1000) {
      return setAmountError("The amount range is $15 - $1000");
    }
    if (!amount) return setAmountError("The amount field is requird!");
    getPrice(amount);

    if (selectedPayment === "crypto") {
      setIsLoading(true);
      cryptoPaymentLinkGenerte(price)
        .then((result) => {
          let data = JSON.parse(result);
          if (data.error === "ok") {
            setCryptoReferecne(data);
            setStep(1);
            setIsLoading(false);
          }
        })
        .catch((error) => console.log("error", error));
    }
    if (selectedPayment === "wire-account") {
      setAmountError("");
      if (step === 0) {
        if (!activeMethod) return setActiveMethodError(true);
        setStep(1);
      } else if (step === 1) {
        setFtError("");
        if (!txn) return setFtError("The txn / reference field is required!");
        setIsLoading(true);
        var raw = "";
        if (label === "createcard") {
          raw = JSON.stringify({
            name: "YehaCard",
            paymont_method: selectedPaymentMethod.id,
            txn: txn,
            amount: amount,
            card_type: "1",
          });

          createOrder(raw)
            .then((res) => res.json())
            .then((res) => {
              // console.log(res.data.status);
              if (!res.data.status) {
                return;
              } else {
                window.location.reload();
              }
            })
            .catch((err) => {
              return;
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          raw = JSON.stringify({
            amount: amount,
            payment_method: selectedPaymentMethod.id.toString(),
            txn: txn,
          });
          FundCardApi(card, raw)
            .then((res) => res.json())
            .then((res) => {
              if (!res.status) {
                return;
              } else {
                window.location.reload();

                // console.log(res.status);
              }
            })
            .catch((err) => {
              return;
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    }
    if (selectedPayment === "chapa") {
      setFtError("");
      setIsLoading(true);
      // var raw = "";
      if (label === "createcard") {
        raw = JSON.stringify({
          price: Number(price.toString().replace(/,/g, "")).toFixed(2),
          amount: amount,
          type: "create-card",
        });
        // console.log(raw);
        createFirstPayment(raw).then((result) => {
          if (result.status) {
            window.location = result.checkout_url;
          } else {
            return;
          }
        });
      } else {
        raw = JSON.stringify({
          price: Number(price.toString().replace(/,/g, "")).toFixed(2),
          amount: amount,
          type: "fund-card",
        });
        // console.log(raw)
        createFirstPayment(raw, card.id).then((result) => {
          if (result.status) {
            window.location = result.checkout_url;
          } else {
            return;
          }
        });
      }
    }
  };
  const changeNumber = (card) => {
    const numberString = card.card_number.toString();
    const groups = [];

    groups.push(numberString.slice(0, 4));
    groups.push("**** ****");
    groups.push(numberString.slice(-4));
    setCardNumber(groups.join("  "));
  };

  useEffect(() => {
    getPaymontMethods();
    label !== "createcard" && label !== "" && changeNumber(card);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  useEffect(() => {
    getPrice(amount !== "" ? amount : 15);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPayment]);

  const getPaymontMethods = async () => {
    let result = await fetchPaymentMethod();
    setPaymentMethods(result.data);
    setIsLoaded(true);
    // paymentMethodChange(result.data[0].title, result.data[0].type);
    setSelecedPayment(result.data[0].type);
  };

  const getPrice = (val) => {
    setAmount(val);
    if (selectedPayment === "wire-account" || selectedPayment === "chapa") {
      let pri = calculatePrice(val);
      setPrice(pri.price);
      // setDiscount(pri.discount);
    }
    if (selectedPayment === "crypto") {
      let pri = calculateUsdtPrice(val);
      setPrice(pri.price);
    }
  };

  return (
    <>
      <div className={`w-full flex flex-col items-center justify-center `}>
        <div
          className={`flex flex-col min-w-[90%] md:min-w-[400px] max-w-[400px] items-center justify-center `}
        >
          <div className="text-slate-500 font-bold text-2xl ">
            {label === "createcard" ? (
              <div className="flex w-full justify-center items-center gap-2">
                <img src={VisaLogo} alt="logo" className="w-auto h-5" />
                <div>Create new card</div>
              </div>
            ) : (
              <div> Fund your card</div>
            )}
          </div>

          <div className=" flex flex-col lg:flex-row w-full justify-start p-5  rounded-md">
            {isLoaded ? (
              <div className="w-full flex flex-col items-center ">
                {label !== "createcard" && (
                  <div className="flex gap-2 items-center bg-slate-300 w-full rounded-md p-2">
                    <div>
                      <img
                        src={
                          card.card_type === "visa" ? VisaLogo : masterCardLogo
                        }
                        alt="logo"
                        className="w-12 h-auto"
                      />
                    </div>
                    <div>{cardNumber}</div>
                  </div>
                )}
                {step === 0 ? (
                  <>
                    <div className="w-full py-4  flex flex-col md:flex-row  gap-5">
                      <div className="flex flex-col w-full gap-2 ">
                        <label className="text-slate-600  text-md">
                          Amount <span className="text-red-500">*</span>
                        </label>
                        <div className="border flex w-full items-center rounded-md">
                          <div className="px-2">
                            <UsdIcon />
                          </div>
                          <input
                            type="number"
                            max={1000}
                            maxLength={4}
                            min={15}
                            value={amount}
                            onChange={(e) => {
                              if (e.target.value <= 1000)
                                setAmount(e.target.value);
                              setPrice(0);
                              setAmountError("The amount range is $15 - $1000");
                              if (e.target.value > 1000) e.target.value = 1000;
                              if (
                                e.target.value <= 1000 &&
                                e.target.value >= 15
                              ) {
                                setAmountError("");
                                return getPrice(e.target.value);
                              }
                            }}
                            placeholder="Enter Amount"
                            className="w-full  border-l  py-3 px-2 rounded-r-md "
                          />
                        </div>
                        <label className="text-red-500 text-sm">
                          {amountError}
                        </label>
                      </div>
                    </div>
                    <hr className="w-full" />
                    <div className="font-bold py-2 text-slate-500">
                      Select Payment method
                    </div>

                    <div className="w-full grid grid-cols-3 gap-2 py-2 ">
                      {paymentMethods.map((val) => (
                        <div key={val.id}>
                          <div
                            onClick={() =>
                              paymentMethodChange(val.title, val.type)
                            }
                            key={"man" + val.id}
                            className={`border rounded-md cursor-pointer ${
                              activeMethod === val.title
                                ? "border-green-500"
                                : activeMethodError
                                ? "border-red-500"
                                : "border-slate-300"
                            } `}
                          >
                            <div
                              key={val.id}
                              className="flex justify-end w-full h-4 "
                            >
                              {activeMethod === val.title && (
                                <CheckIcon
                                  key={"icon" + val.id}
                                  styles={{
                                    height: "1.0rem",
                                    width: "1.0rem",
                                    color: "green",
                                  }}
                                />
                              )}
                            </div>
                            <div
                              key={"logo" + val.id}
                              className="text-slate-500 pb-3 w-full flex justify-center"
                            >
                              <img
                                src={`https://api.yehadigital.com/storage/${val.logo}`}
                                alt=""
                                className="w-auto h-10"
                              />
                            </div>
                          </div>
                          <div
                            key={"title" + val.id}
                            className={`text-md text-center w-full ${
                              activeMethod === val.title
                                ? "text-green-500"
                                : "text-slate-500"
                            }`}
                          >
                            {val.title}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div></div>
                  </>
                ) 
                : selectedPayment === "wire-account" && step === 1 ? (
                  <>
                    <div className="w-full py-6">
                      <div className="flex flex-col w-full gap-2 ">
                        <label className="text-slate-600  text-md">
                          Txn / Reference Number
                          <span className="text-red-500">*</span>
                        </label>
                        <div className=" flex w-full items-center rounded-md">
                          <input
                            type="text"
                            placeholder="Transaction code"
                            value={txn}
                            onChange={(e) => {
                              setTxn(e.target.value);
                            }}
                            className="w-full border py-3 px-2 rounded-md "
                          />
                        </div>
                        <label className="text-red-500 text-sm">
                          {ftError}
                        </label>
                      </div>
                      <hr className="w-full" />
                    </div>
                  </>
                )
                 : selectedPayment === "crypto" && step === 1 ? (
                  <>
                    <div className="w-full py-3 flex flex-col space-y-3 items-center  -m-2">
                      <div className="w-full flex flex-col py-2">
                        <div className="text-sm">Payment Amount</div>
                        <div className="font-bold">{price} USDT</div>
                      </div>
                      <div className="w-full flex justify-between py-2 bg-slate-50 items-center">
                        <div className="flex gap-1">
                          <img src={UsdtLogo} alt="" className="h-6 w-6" />
                          <div>USDT</div>
                        </div>
                        <div className="text-md">Tron ( TRC20 )</div>
                      </div>
                      <div className="w-full flex sm:justify-between gap-1 py-2">
                        <div className="hidden sm:flex">Address:</div>
                        <div className="flex gap-1 items-center">
                          <div className="text-sm">
                            {walletAddress}
                          </div>
                          {walletAddress && (
                            <button
                              onClick={() =>
                                handleCopy(walletAddress)
                              }
                              className=" text-slate-700 px-1  rounded"
                            >
                              {!isCopied ? (
                                <Copy className={"h-5 w-5"} />
                              ) : (
                                <CopiedIcon />
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                      <div>
                        <QRCode
                          value={walletAddress}
                          size={150}
                        />
                      </div>
                      <div className="w-full bg-red-100 rounded-md flex gap-2 p-2 align-top">
                        <div>
                          <CircularInfoIcon
                            className={"h-4 w-4 text-red-400 "}
                          />
                        </div>
                        <div className="text-xs flex flex-col gap-2 font-semibold">
                          <div className="w-full">
                            1. Please keep the recharge amount consistent with
                            the order amount, otherwise it will lead to capital
                            loss.
                          </div>
                          <div>2. This address only accept TRC20</div>
                          <div>
                            3. Please do not deposit any other assets to this
                            address
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full mt-3">
                      <MainButton
                        onClick={cryptoOrderSubmit}
                        isLoading={isLoading}
                        label={"Paid"}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {selectedPayment === "crypto" && step === 0 && (
                  <div className="w-full flex flex-col space-y-2">
                    <div className="flex w-full justify-between">
                      <div className="flex flex-col space-y-2">
                        <div className=" bg-slate-300 rounded-md flex gap-2 p-2 text-slate-700">
                          <img src={UsdtLogo} alt="" className="h-6 w-6" />
                          <div>USDT</div>
                        </div>

                        <div className="text-slate-400 rounded-md border border-slate-300 text-xs text-center px-2 py-1">
                          Tron (TRC20)
                        </div>
                      </div>
                      <div className="font-bold text-[#25a2ba]">
                        {price} USDT
                      </div>
                    </div>
                    <div>Payment Amount: {price} USDT</div>
                    <hr />
                    <button className="w-full">
                      <MainButton
                        label={step === 0 ? "Continue" : "Confirm Payment"}
                        onClick={submit}
                        isLoading={isLoading}
                      />
                    </button>
                  </div>
                )}
                {selectedPayment === "wire-account" && (
                  <div className="w-full   flex flex-col  gap-5 justify-between">
                    <div className="flex flex-col items-start w-full">
                      <div className="flex flex-col sm:flex-row w-full justify-between py-2">
                        <div className="flex w-full gap-2">
                          <div className="text-slate-400 font-semibold">
                            Price:
                          </div>
                          <div className="text-slate-500 flex gap-2 font-bold">
                            <div>{price.toLocaleString()} ETB</div>
                            {/* <div className="text-slate-300 line-through">
                              {price}
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="text-slate-400  font-semibold ">
                          {/* Account: */}
                        </div>
                        <div className="w-full overflow-wrap break-words max-w-[500px]  overflow-hidden text-slate-500">
                          <div className="w-full">
                            <div className="flex gap-2">
                              <div className="flex gap-2 items-center">
                                <div>{address}</div>
                                {address && (
                                  <button
                                    onClick={() => handleCopy(address)}
                                    className=" text-slate-700 px-1  rounded"
                                  >
                                    {!isCopied ? (
                                      <Copy className={"h-5 w-5"} />
                                    ) : (
                                      <CopiedIcon />
                                    )}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="text-slate-400  font-semibold">
                          {/* Name: */}
                        </div>
                        <div className="w-full overflow-wrap break-words max-w-[500px]  overflow-hidden">
                          <div className="w-full">
                            {
                              <div className="flex gap-2 text-slate-500">
                                <div>{holder}</div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="text-red-700 bg-red-100 p-2  max-w-[600px] text-center rounded-md mt-2">
                        {/* <p> We only accept payment from the account holder!</p> */}
                        <p className="text-left text-sm w-full">
                        Please make the payment directly from your own account. Third-party payments are not accepted, kindly do not include any reference or note in the transaction.
                        </p>
                      </div>
                    </div>
                    <hr />
                    <button className="w-full ">
                      <MainButton
                        label={step === 0 ? "Continue" : "Confirm Payment"}
                        onClick={submit}
                        isLoading={isLoading}
                      />
                    </button>
                  </div>
                )}
                {selectedPayment === "chapa" && step === 0 && (
                  <div className="w-full   flex flex-col  gap-5 justify-between">
                    <div className="flex flex-col items-start w-full">
                      <div className="flex flex-col sm:flex-row w-full justify-between py-2">
                        <div className="flex w-full gap-2">
                          <div className="text-slate-400 font-semibold">
                            Price:
                          </div>
                          <div className="text-slate-500 flex gap-2 font-bold">
                            <div>{price.toLocaleString()} ETB</div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="flex gap-2">
                        <div className="text-slate-400  font-semibold ">
                          Link:
                        </div>
                        <div className="flex gap-2">
                          <div className="flex gap-2 items-center">
                            <div className="truncate max-w-[200px]">
                              {chapaAddress}
                            </div>
                            {chapaAddress && (
                              <button
                                onClick={() => handleCopy(chapaAddress)}
                                className=" text-slate-700 px-1  rounded"
                                title={chapaAddress}
                              >
                                {!isCopied ? (
                                  <Copy className={"h-5 w-5"} />
                                ) : (
                                  <CopiedIcon />
                                )}
                              </button>
                            )}
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <hr />
                    <button className="w-full ">
                      <MainButton
                        label={"Pay with Chapa"}
                        onClick={submit}
                        isLoading={isLoading}
                      />
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="w-full min-w-[290px] md:min-w-[400px] max-w-[500px] flex flex-col gap-3">
                <div className="w-full text-slate-500">
                  Amount range:
                  <span className="text-green-500">$15 - $1000</span>
                </div>
                <div className="w-full flex flex-col gap-2 my-2">
                  <VirtualCardShimmer
                    className={
                      "w-1/2 max-w-[300px] h-[10px] bg-slate-200 rounded-md -start"
                    }
                  />
                  <VirtualCardShimmer
                    className={
                      "w-full max-w-[600px] h-[50px] bg-slate-200 rounded-md"
                    }
                  />
                </div>
                <div className="w-full flex flex-col gap-2 my-2">
                  <VirtualCardShimmer
                    className={
                      "w-1/2 max-w-[300px] h-[10px] bg-slate-200 rounded-md -start"
                    }
                  />
                  <VirtualCardShimmer
                    className={
                      "w-full max-w-[600px] h-[50px] bg-slate-200 rounded-md"
                    }
                  />
                </div>
                <div className="w-full flex flex-col gap-2 my-2">
                  <VirtualCardShimmer
                    className={
                      "w-1/2 max-w-[300px] h-[10px] bg-slate-200 rounded-md -start"
                    }
                  />
                  <VirtualCardShimmer
                    className={
                      "w-full max-w-[600px] h-[50px] bg-slate-200 rounded-md"
                    }
                  />
                </div>
                <br />
                <VirtualCardShimmer
                  className={
                    "w-full max-w-[600px] h-[50px] bg-slate-200 rounded-md"
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentModal;
