import { baseUrl, token } from "../constants/constants";

export const createFirstPayment = async (raw, id) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let response = await fetch(
    `${baseUrl}generate-payment-link/${id}`,
    requestOptions
  );
  if (response.ok) return response.json();
  return;
};

export const cryptoPaymentLinkGenerte = async (amount) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    amount: amount,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    "https://api.yehadigital.com/api/generate-payment",
    requestOptions
  ).then((response) => response.text());
};
